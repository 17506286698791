export default [
  {
    path: '/checker',
    name: 'main',
    component: () => import('@/apps/checker/views/Stores/Stores'),
  },

  {
    path: '/:store/orders',
    name: 'store',
    component: () => import('@/apps/checker/views/Orders/Orders'),
  },
];
