/* eslint-disable no-shadow, no-param-reassign */
import { api } from 'shared/core';

const state = {
  values: {
    items: [],
    meta: {},
  },
  lockActions: [],
};

const getters = {
  values: state => state.values,
};

const actions = {
  setValuesAction: ({ commit }, values) => {
    commit('setValues', values);
  },
  handleLockAction: ({ commit, state }, { id, action }) => {
    if (state.lockActions.includes(id)) return;
    commit('addLockAction', id);

    let uri;
    let newLockStateValue;

    if (action === 'lock') {
      uri = '/dashboard/store/box/close-lock/';
      newLockStateValue = 'closed';
    } else if (action === 'unlock') {
      uri = '/dashboard/store/box/open-lock/';
      newLockStateValue = 'opened';
    }

    let handler = () => api.endpoint.courier.post(uri, { store_id: Number(id) });
    if (IS_DEV) handler = () => Promise.resolve();

    handler()
      .then(() => {
        let target = { ...state.values.items.find(i => i.id == id) };
        target.state_lock = newLockStateValue;

        // TODO: remove when websockets will be available
        setTimeout(() => {
          commit('addOrUpdateValue', target);
          commit('removeLockAction', id);
        }, 3000);
      })
      .catch(e => {
        commit('removeLockAction', id);
        console.error(e);
      });
  },
};

const mutations = {
  setValues: (state, values) => {
    state.values = values;
  },
  addOrUpdateValue: (state, value) => {
    const { id } = value;

    let items = state.values.items.slice();
    const prevIndex = items.findIndex(i => Number(i.id) === Number(id));

    if (prevIndex !== -1) items.splice(prevIndex, 1, value);
    else items = [value].concat(items);

    state.values = {
      ...state.values,
      items,
    };
  },
  addLockAction: (state, storeId) => {
    state.lockActions = [storeId].concat(state.lockActions.filter(id => id !== storeId));
  },
  removeLockAction: (state, storeId) => {
    state.lockActions = state.lockActions.filter(id => id !== storeId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
