import { bus, api } from 'shared/core';

class Channel {
  url = '/dashboard/profile/get-channel/';
  endpoint = api.endpoint.checker;

  handler = ev => {
    try {
      const message = JSON.parse(ev.data);

      if (message && message.data && message.data.order) {
        return bus.emit('order', message.data.order);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export default new Channel();
