import { render, staticRenderFns } from "./App.vue?vue&type=template&id=597b2f22&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../home/gitlab-runner/.nvm/versions/node/v12.14.1/lib/node_modules/@briskly/vue-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports