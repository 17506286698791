import { bus } from 'shared/core';

class InterfacesEvents {
  _store;

  init(store, router) {
    this._store = store;
    this._router = router;
    bus.on('authInterfaces', this.onInterfaces);
  }

  onInterfaces = interfaces => {
    this._store.commit('core$interfaces/setValues', interfaces);

    const interfacesNames = Object.keys(interfaces);

    if (interfacesNames.filter(v => interfaces[v]).length === 1) {
      const [$interface] = interfacesNames.filter(v => interfaces[v]);

      switch ($interface) {
        case 'courier':
          this._router.push({ name: 'courier$stores' });
      }
    }
  };
}

export default new InterfacesEvents();
