import Vue from 'vue';
import Router from 'vue-router';
import { getAppRoutes } from 'shared/core/utils';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/core/views/Index'),
    children: getAppRoutes(),
  },

  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/core/views/Preloader'),
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

export default router;
