import { bus } from 'shared/core';

class MapEvents {
  _store;

  init(store) {
    this._store = store;
    bus.on('core$map/init', this.onInit);
  }

  onInit = bool => {
    this._store.commit('core$map/setInit', bool);
  };
}

export default new MapEvents();
