/* eslint-disable no-shadow, no-param-reassign */

const state = {
  values: {},
};

const getters = {
  values: state => state.values,
  getValue: state => id => state.values[id],
};

const actions = {
  setValuesAction: ({ commit }, { store_id, values }) => {
    commit('setValues', { store_id, values });
  },
  addValueAction: ({ commit }, value) => {
    commit('addValue', value);
  },
};

const mutations = {
  setValues: (state, { store_id, values }) => {
    if (store_id) {
      state.values = {
        ...state.values,
        [store_id]: values,
      };
    } else console.error(`[courier$states]: store not found for states`);
  },

  addValue: (state, value) => {
    let target = state[value.store_id];

    if (!target) {
      state.values = {
        ...state.values,
        [value.store_id]: { items: [value], meta: {} },
      };

      target = state.values[value.store_id];
    }

    let items = target.items.slice();
    const prevIndex = items.findIndex(i => Number(i.id) === Number(value.id));

    if (prevIndex !== -1) items.splice(prevIndex, 1, value);
    else items = [value].concat(items);

    state.values = {
      ...state.values,
      [value.store_id]: {
        ...state.values[value.store_id],
        items,
      },
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
