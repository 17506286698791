const state = {
  values: {},
};

const getters = {
  values: state => state.values,
  hasAccess: state => value => state.values[value],
  isEmpty: state => !Object.keys(state.values).length,
};

const actions = {};

const mutations = {
  setValues: (state, values) => {
    state.values = { ...state.values, ...values };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
