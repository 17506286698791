export default [
  {
    path: '/courier/stores',
    component: () => import('@/apps/courier/views/Stores/Stores'),
    children: [
      {
        path: '',
        name: 'stores',
        component: () => import('@/apps/courier/views/Stores/All/All'),
      },

      {
        path: 'map',
        name: 'stores-on-map',
        component: () => import('@/apps/courier/views/Stores/Map/Map'),
      },

      {
        path: ':store',
        name: ':store',
        component: () => import('@/apps/courier/views/Stores/One/One'),
      },
    ],
  },
];
