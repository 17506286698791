const state = {
  init: false,
};

const getters = {
  init: state => state.init,
};

const actions = {};

const mutations = {
  setInit: (state, bool) => {
    state.init = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
