import 'animate.css';
import 'normalize.css';
import 'swiper/dist/css/swiper.css';
import '@/core/styles.scss';
import 'regenerator-runtime';
import 'sweetalert2';

import Vue from 'vue';
import Vuex from 'vuex';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { api, bus, Channel, utils } from 'shared/core';
import { auth, personal } from 'shared/modules';
import App from '@/core/App';
import router from '@/core/router';
import store from '@/core/store';

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$bus = bus;
Vue.use(Vuex);
Vue.use(VueAwesomeSwiper);

api.setEndpoint('business');

utils.getEvents().forEach(({ value }) => value.init(store, router));
utils.getChannels().forEach(({ value }) => new Channel(value));

personal.connect();
auth.connect({ store, router });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

import('@/core/scripts/map').then(mod => mod.default());
